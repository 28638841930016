<template>
  <div class="public-studioAdmin-body">
    <StudioAdminTabs :list="tabs" :newIndex="view" @change="tabsChange"></StudioAdminTabs>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox public-form-studioAdmin">
          <div>
            <el-input v-model="form.search_name" placeholder="请输入成果标题" class="input" style="margin-left: 0"></el-input>

            <el-input v-model="form.username" placeholder="获奖/贡献/提交人姓名" class="input"></el-input>

            <el-select v-model="form.search_certificat_level" placeholder="成果级别" class="sid"
              style="width: 150px; margin-left: 10px">
              <el-option v-for="item in certificatList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.search_certificat_type" placeholder="成果类型" class="sid"
              style="width: 150px; margin-left: 10px">
              <el-option v-for="item in radioList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.show_type" placeholder="展示状态" class="sid" style="width: 150px; margin-left: 10px"
              v-if="view == 0">
              <el-option v-for="item in optiones_display" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.status" placeholder="审核状态" class="sid" style="width: 150px; margin-left: 10px"
              v-if="view == 1">
              <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-button class="ml10" type="primary" @click="search()">查询</el-button>
            <el-button @click="reset()">重置</el-button>
          </div>
          <div class="mt10" v-if="view == 0">
            <el-button class="btn" @click="add(form.search_achievement_type)">新增研究成果</el-button>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :stripe="true" class="public-table-studioAdmin">
          <el-table-column type="index" label="序号" align="center" width="70">
          </el-table-column>
          <el-table-column label="成果信息" head-align="center">
            <template slot-scope="scope">
              <div class="studioAdmin-info">
                <div class="info-cover">
                  <el-image :src="scope.row.resource_url" :preview-src-list="[scope.row.resource_url]">
                  </el-image>
                  <span class="cover-labelTop" v-if="scope.row.top == 1">{{ scope.row.top == 1 ? "置顶" : "" }}</span>
                </div>
                <div class="info-box">
                  <p class="info-title">{{ scope.row.title }}</p>
                  <div class="info-block">
                    <span class="block-item">{{ scope.row.certificat_level_str }}</span>
                    <span class="block-item">{{ scope.row.certificat_type_name }}</span>
                    <span class="block-item">{{ scope.row.usernames }}荣获/贡献</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建人/创建时间" align="center" width="200">
            <template slot-scope="scope">
              {{ scope.row.create_time }}
              <br>
              {{ scope.row.user_id_name }}
            </template>
          </el-table-column>
          <el-table-column label="展示状态" align="center" width="100" v-if="view == 0">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show" active-color="#3489FF" :active-value="1" :inactive-value="0"
                @change="isShowChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center" width="100" v-if="view == 1">
            <template slot-scope="scope">
              <p class="text-color-orange" v-if="scope.row.status == 1">待审核</p>
              <p class="text-color-red" v-if="scope.row.status == 3">未通过</p>
              <p class="text-color-grey" v-if="scope.row.status == 2">已通过</p>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" align="center" width="230">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-blue" @click="detail(scope.row.id)">详情</el-button>
              <template v-if="view == 0">
                <el-button type="text" class="text-color-blue" @click="edit(scope.row.id)">编辑</el-button>
                <el-button type="text" @click="del(scope.row.id)" class="text-color-red">删除</el-button>
                <el-button type="text" class="text-color-blue" @click="top(scope.row)" v-if="scope.row.is_show == 1">{{ scope.row.top == 2 ? "置顶" : "取消置顶" }}</el-button>
              </template>
              <template v-if="view == 1 && scope.row.status == 1">
                <el-button type="text" @click="examineButton(scope.row, 2)" class="text-color-blue">通过</el-button>
                <el-button type="text" @click="examineButton(scope.row, 3)" class="text-color-red">不通过</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { DelCertificat } from "@/api/teacherStudio";
import { CertificateType } from "@/api/teacherStudio/index.js";
export default {
  data() {
    return {
      optiones_display: [
        {
          id: 1,
          name: "展示",
        },
        {
          id: 2,
          name: "隐藏",
        },
        {
          id: 3,
          name: "置顶",
        },
      ],
      optiones: [
        {
          id: 1,
          name: "待审核",
        },
        {
          id: 2,
          name: "已通过",
        },
        {
          id: 3,
          name: "未通过",
        },
      ],
      //表格数据
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        search_name: "",
        page: 1,
        limit: 10,
        certificat_type: "",
        status: "",
        username: "",
        search_achievement_type: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
        search_certificat_level: "",
        search_certificat_type: "",
        order_type: 1,
        show_type: ""
      },
      certificatList: [
        { id: 1, name: "国家级" },
        { id: 2, name: "省级" },
        { id: 3, name: "市级" },
        { id: 4, name: "区县级" },
        { id: 5, name: "校级" },
        { id: 6, name: "其他" },
      ],
      radioList: [],
      //导航
      tabs: [
        {
          label: "研究成果管理",
          count: 0
        },
        {
          label: "研究成果审核",
          count: 0
        }
      ],
      //页面索引
      view: 0,
      //审核表单数据
      examineForm: {
        id: 0,
        teaching_studio_id: localStorage.getItem("studioId"),
        status: 0,
        refusal: ''
      }
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //判断是否审核页
    if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 2) {
      this.view = 1;
      this.form.order_type = 2;
    }
    this.init();
  },
  computed: {},
  methods: {
    //新增
    add(val) {
      this.$router.push({
        path: "/ResearchAchievementEdit",
        query: {
          achievement_type: val
        }
      })
    },
    //详情
    detail(tid) {
      let routeData = this.$router.resolve({
        path: "/CertificateDetail",
        query: {
          id: tid,
          is_from: 1
        },
      });
      window.open(routeData.href, '_blank');
    },
    //获取成果证书类型
    getCertificatesType() {
      CertificateType({ teaching_studio_id: localStorage.getItem("studioId") }).then((res) => {
        this.radioList = res.data.data || [];
      });
    },
    //切换回调
    tabsChange(val) {
      this.view = val;
      this.form.search_name = "";
      this.form.page = 1;
      this.form.certificat_type = "";
      this.form.status = "";
      this.form.username = "";
      this.form.search_certificat_level = "";
      this.form.search_certificat_type = "";
      this.form.order_type = val == 0 ? 1 : 2;
      this.form.show_type = "";
      this.tableData = []
      this.init();
    },
    //初始化
    init() {
      this.getCertificatesType()
      this.getList();
      this.examineCount();
    },
    //列表
    getList() {
      this.$axios
        .get("TeachingStudioCertificate/lists", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        });
    },
    //删除
    del(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.delApi,
        id,
      });
    },
    //删除接口
    delApi(id) {
      DelCertificat({
        id,
        teaching_studio_id: localStorage.getItem("studioId"),
      }).then((res) => {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        this.getList();
      });
    },
    //搜索
    search() {
      this.form.page = 1;
      this.getList();
    },
    //重置
    reset() {
      this.form.search_name = "";
      this.form.page = 1;
      this.form.certificat_type = "";
      this.form.status = "";
      this.form.username = "";
      this.form.search_certificat_level = "";
      this.form.search_certificat_type = "";
      this.form.show_type = "";
      this.getList();
    },
    //每页条数回调
    handleSizeChange(val) {
      this.form.limit = val;
      this.getList();
    },
    //当前页回调
    handleCurrentChange(val) {
      this.form.page = val;
      this.getList();
    },
    //编辑
    edit(id) {
      this.$router.push({
        path: "/ResearchAchievementEdit",
        query: {
          achievement_type: 1,
          id
        }
      })
    },
    //是否显示回调
    isShowChange(e) {
      this.$axios
        .put("TeachingStudioCertificate/set_is_show", {
          id: e.id
        })
        .then(() => {
          this.getList();
        });
    },
    //置顶回调
    top(e) {
      this.$axios
        .put("TeachingStudioCertificate/top", {
          id: e.id,
          top: e.top == 1 ? 2 : 1
        })
        .then(() => {
          this.getList();
        });
    },
    //审核按钮
    examineButton(e, val) {
      this.examineForm.id = e.id;
      this.examineForm.status = val;
      if (val == 2) {
        this.myConfirm({
          content: `是否确定通过审核？`,
          fn: this.examine
        });
      }
      if (val == 3) {
        this.$prompt('', '审核不通过', {
          customClass: 'examine-prompt',
          closeOnClickModal: false,
          showClose: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPattern: /^.+$/m,
          inputPlaceholder: '请输入拒绝理由',
          inputErrorMessage: '请输入拒绝理由'
        }).then((e) => {
          if (e.action == "confirm") {
            this.examineForm.refusal = e.value;
            this.examine();
          }
        }).catch(() => {

        });
      }
    },
    //审核
    examine() {
      this.$axios
        .put("TeachingStudioCertificate/status", this.examineForm)
        .then(() => {
          this.getList();
          this.examineCount();
          this.examineForm.refusal = "";
        });
    },
    //审核不通过（提交）
    examinePopoverSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.examine();
        }
      });
    },
    //审核统计
    examineCount() {
      this.$axios
        .get("index/TeachingStudioCertificate/status_certificate", { params: { id: this.form.teaching_studio_id, achievement_type: this.form.search_achievement_type } })
        .then((res) => {
          this.tabs[1].count = res.data.data.status_certificate;
        });
    },
  },
};
</script>
<style lang="less" scoped>

</style>
